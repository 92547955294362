import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Unstable_Grid2";
import Item from "@mui/material/Unstable_Grid2";
import {  useEffect, useState, useMemo } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import useAuth from "../hooks/useAuth";
import Config from "../models/Config";
import Cache from "../models/Cache";
import { ClassResults } from "../models/StandingResults";
import { StandingResult } from "../models/StandingResult";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

function StandingsPage() {
  const user = useAuth();
  const [classResults, setClassResults] = useState<ClassResults[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [classes, setClasses] = useState<string[]>([]);

  useEffect(() => {
    document.title = `${Config.TAB_PREFIX}Standings`;
  }, [])

  const getClassResults = useMemo(() => {
    const fetchData = async (className: string) => {
      setIsLoading(true);
      try {
        let data;
        let jsonData;
        data = await fetch(`${Config.SHEETS_URL}/values/${encodeURIComponent("Standings!G2:G10")}?key=${Config.SHEETS_KEY}`, {
          method: 'GET'
        });
        jsonData = await data.json();
        const classes = jsonData.values.map((classPayload: any) => classPayload[0]);
        setClasses(classes);
        Cache.ClassesList.data = classes;

        data = await fetch(`${Config.SHEETS_URL}/values/${encodeURIComponent("Standings!A2:D500")}?key=${Config.SHEETS_KEY}`, {
          method: 'GET'
        });
        jsonData = await data.json();
        const classResults = jsonData.values.reduce(
          (acc: ClassResults[], standingPayload: any) => {
            const result = new StandingResult(standingPayload);
            const classGroup = acc.find((d) => d.class === result.class);
            if (classGroup) {
              if (!classGroup.results) {
                classGroup.results = [];
              }
              classGroup.results.push(result);
            } else {
              acc.push(new ClassResults(result.class, [result]));
            }
            return acc;
          },
          [] as ClassResults[],
        );
        const filteredResults = classResults.filter((classResult: ClassResults) => classResult.class === className);
        setClassResults(filteredResults);
        Cache.StandingResultPage.set(className, { data: filteredResults, timestamp: Date.now() });
      } catch (error) {
        // Handle error
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
  
    return async (className: string) => {
      if (Cache.StandingResultPage.has(className)) {
        setClassResults(Cache.StandingResultPage.get(className)!.data);
        setClasses(Cache.ClassesList.data);
      } else {
        fetchData(className);
      }
    };
  }, [user]);
  
  // useEffect hook to periodically invalidate cache after 15 minutes
  useEffect(() => {
    const intervalId = setInterval(() => {
      for (const [classId, cachedData] of Cache.StandingResultPage.entries()) {
        if (Date.now() - cachedData.timestamp >= Config.CACHE_EXPIRATION_TIME) {
          Cache.StandingResultPage.delete(classId);
        }
      }
    }, Config.CACHE_EXPIRATION_TIME);
  
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getClassResults('Open');
  }, [getClassResults]);


  const handleChange = (event: SelectChangeEvent) => {
    getClassResults(event.target.value);
  };

  return (
    <LoadingOverlay active={isLoading} className="loading-overlay">
      <>
          <Container
            maxWidth="sm"
            sx={{ marginBottom: "60px" }}
            className="page-header"
          >
            {!classResults && !isLoading && (
              <div className="center-text">
                <h2>No results available yet.</h2>
              </div>
            )}
            <Stack spacing={2}>
              {classResults &&
                classResults.map((classResult) => {
                  return (
                    <>
                      <div>
                        <div>
                          <InputLabel id="demo-simple-select-label">Class Name</InputLabel>
                          <Select
                            value={classResult.class.toString()}
                            label="Class"
                            onChange={handleChange}
                            sx={{ width: "100%",
                            marginBottom: "10px"
                            }}
                          >
                            {classes.map((key: string) => {
                              if (key) {
                                return (
                                  <MenuItem key={key} value={key}>
                                    {key}
                                  </MenuItem>
                                );
                              }
                            }, [])}
                          </Select>
                        </div>
                        <div className="card-row--primary show-result-division-name">
                          {classResult.className.toUpperCase()}
                        </div>
                        {classResult.finalResults &&
                          classResult.finalResults.map((result) => {
                            return (
                              <>
                                <Grid
                                  container
                                  spacing={1}
                                  key={result.name + result.horse}
                                >
                                  <Grid className="show-result-position" xs={1}>
                                    <Item>{result.position}</Item>
                                  </Grid>
                                  <Grid className="show-result-name" xs={5}>
                                    <Item>{result.name}</Item>
                                  </Grid>
                                  <Grid className="show-result-horse" xs={5}>
                                    {result.horse}
                                  </Grid>
                                  <Grid className="show-result-time" xs={1}>
                                    {result.points}
                                  </Grid>
                                </Grid>
                              </>
                            );
                          })}
                      </div>
                    </>
                  );
                })}
            </Stack>
          </Container>
        </>
    </LoadingOverlay>
  );
}
export default StandingsPage;
