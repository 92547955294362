import { useState, useEffect, useMemo } from 'react';
import Show from '../models/Show';
import useAuth from '../hooks/useAuth';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import ShowMonth from '../models/ShowMonth';
import moment from 'moment';
import PageHeader from '../components/PageHeader';
import Modal from '@mui/material/Modal';
import { Box } from '@mui/material';
import LinkButton from '../components/LinkButton';
import PullToRefresh from 'react-simple-pull-to-refresh';
import LoadingOverlay from 'react-loading-overlay-ts';
import Config from '../models/Config';
import Cache from '../models/Cache';
import ScheduledShowRow from '../components/ScheduledShowRow';

const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
  whiteSpace: 'pre-line',
};

function SchedulePage() {
  const user = useAuth();
  const [showMonths, setResult] = useState<ShowMonth[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [showForModal, setShowForModal] = useState<Show>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    document.title = `${Config.TAB_PREFIX}Schedule`
  }, [])

  const getSchedule = useMemo(() => {
  
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const data = await fetch(`${Config.SHEETS_URL}/values/${encodeURIComponent("Shows!A2:G50")}?key=${Config.SHEETS_KEY}`, {
          method: 'GET'
        });
        const jsonData = await data.json();
        const groupByMonths = jsonData.values.reduce((acc: ShowMonth[], showPayload: any) => {
          const show = new Show(showPayload);
          if (show.title &&  moment(show.date) >= moment()) {
            const month = moment(show.date).format('MMMM');
            const currentMonth = acc.find((m) => m.month === month);
            if (currentMonth) {
              currentMonth.shows.push(show);
            } else {
              acc.push(new ShowMonth(month, [show]));
            }
          }
          return acc;
        }, [] as ShowMonth[]);
        Cache.SchedulePage.data = groupByMonths;
        Cache.SchedulePage.timestamp = Date.now();
        setResult(groupByMonths);
      } catch (error) {
        // Handle error
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
  
    return async () => {
      if (Date.now() - Cache.SchedulePage.timestamp < Config.CACHE_EXPIRATION_TIME) {
        setResult(Cache.SchedulePage.data);
      } else {
        fetchData();
      }
    };
  }, [user]);
  
  // useEffect hook to periodically invalidate cache after 15 minutes
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (Date.now() - Cache.SchedulePage.timestamp >= Config.CACHE_EXPIRATION_TIME) {
        Cache.SchedulePage.timestamp = 0; // Invalidate cache if expired
      }
    }, Config.CACHE_EXPIRATION_TIME);
  
    return () => clearInterval(intervalId);
  }, []);
  
  useEffect(() => {
    getSchedule();
  }, [getSchedule]);

  const onCloseModal = () => setOpen(false);

  const onRowClick = (show: Show) => {
    return () => {
      setShowForModal(show);
      setOpen(true);
    };
  };

  return (
    <LoadingOverlay active={isLoading} className="loading-overlay">
      <PullToRefresh onRefresh={getSchedule}>
        <>
          <Container maxWidth="sm" sx={{ marginBottom: '60px' }}>
            <PageHeader header="Schedule" />
            {showMonths.length === 0 && !isLoading && (
              <div className="center-text">
                <h2>No shows scheduled</h2>
              </div>
            )}
            <Stack spacing={2}>
              {showMonths &&
                showMonths.map((value) => {
                  return (
                    <>
                      <h2 key={value.month}>{value.month}</h2>
                      {value.shows &&
                        value.shows.map((show) => {
                          return (
                            <ScheduledShowRow
                              show={show}
                              onRowClick={onRowClick}
                            ></ScheduledShowRow>
                          );
                        })}
                    </>
                  );
                })}
            </Stack>
          </Container>
          <Modal
            open={open}
            onClose={onCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modalStyle}>
              <div>
                <LinkButton
                  className="center"
                  link={`https://www.google.com/maps/search/?api=1&query=${showForModal?.address1} ${showForModal?.cityState}`}
                  label="Open Navigation"
                />
              </div>
              {showForModal?.details}
            </Box>
          </Modal>
        </>
      </PullToRefresh>
    </LoadingOverlay>
  );
}
export default SchedulePage;
