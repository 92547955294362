import { RiderShowResults } from '../models/RiderShowResults';
import { DivisionResults } from '../models/DivisionResults';
import { ClassResults } from './StandingResults';

export default class Cache {
    public static RiderResultPage = new Map<string, { data: RiderShowResults[], timestamp: number }>();
    public static SchedulePage = { data: [], timestamp: 0 };
    public static ShowResultsPage = { data: [], timestamp: 0 };
    public static ShowResultPage = new Map<string, { data: DivisionResults[], timestamp: number }>();
    public static StandingResultPage = new Map<string, { data: ClassResults [], timestamp: number }>();
    public static ClassesList = { data: [], timestamp: 0 };
}