import Box from "@mui/material/Box";

interface PageHeaderProps {
  header: string;
}

function PageHeader ({ header }: PageHeaderProps) {
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: { xs: "flex", md: "none" },
        textAlign: "center",
        h1: { margin: "auto" },
      }}
    >
      <h1>{header}</h1>
    </Box>
  );
};

export default PageHeader;
