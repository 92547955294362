
export class ShowResult {
    id: string;
    division: string;
    name: string;
    horse: string;
    time: string;
    constructor(showResultPayload: any) {
        this.division = showResultPayload[0];
        this.name =  showResultPayload[1];
        this.horse =  showResultPayload[2];
        this.time =  showResultPayload[3];
        this.id = this.division + this.name + this.horse;
    }
}