import React from 'react';

const loadCalendarImage = (day: number): string => {
  const images = require.context('../assets/dates', false, /\.png$/);
  try {
    return images(`./calendar${day}.png`);
  } catch (error) {
    console.error(`Image for day ${day} not found`, error);
    return ''; // Or return a placeholder image path if needed
  }
};
interface DateIconProps {
  day: number;
}

const DateIcon: React.FC<DateIconProps> = ({ day }) => {
  return (
    <img
      src={loadCalendarImage(day)}
      alt={`calendar day ${day}`}
      className="date-icon"
    />
  );
};

export default DateIcon;
