import { ShowResult } from "./ShowResult";

export class DivisionResults {
    division: string;
    divisionName: string
    results : ShowResult[];
    constructor(division: string, results: ShowResult[]) {
        this.division = division;
        this.divisionName = division;
        this.results = results;
    }
}