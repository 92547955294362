import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Unstable_Grid2";
import Item from "@mui/material/Unstable_Grid2";
import {  useEffect, useState, useMemo } from "react";
import LoadingOverlay from "react-loading-overlay-ts";
import useAuth from "../hooks/useAuth";
import { ShowResult } from "../models/ShowResult";
import { useNavigate, useParams } from "react-router-dom";
import { DivisionResults } from "../models/DivisionResults";
import ReplyIcon from "@mui/icons-material/Reply";
import Config from "../models/Config";
import Cache from "../models/Cache";

function ShowResultPage() {
  const user = useAuth();
  const navigate = useNavigate();
  const { showId } = useParams();
  const [divisionResults, setDivisionResults] = useState<DivisionResults[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    document.title = `${Config.TAB_PREFIX}Show Results`
  }, [])

  const getShowResults = useMemo(() => {
    const fetchData = async (showId: string) => {
      setIsLoading(true);
      try {
        const data = await fetch(`${Config.SHEETS_URL}/values/${encodeURIComponent(`${showId}!A2:D500`)}?key=${Config.SHEETS_KEY}`, {
          method: 'GET'
        });
        const jsonData = await data.json();
        const divisionResults = jsonData.values.reduce(
          (acc: DivisionResults[], resultPayload: any) => {
            const result = new ShowResult(resultPayload);
            const division = acc.find((d) => d.division === result.division);
            if (division) {
              if (!division.results) {
                division.results = [];
              }
              division.results.push(result);
            } else {
              acc.push(new DivisionResults(result.division, [result]));
            }
            return acc;
          },
          [] as DivisionResults[],
        );
        setDivisionResults(divisionResults);
        Cache.ShowResultPage.set(showId, { data: divisionResults, timestamp: Date.now() });
      } catch (error) {
        // Handle error
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
  
    return async (showId: string) => {
      if (Cache.ShowResultPage.has(showId)) {
        setDivisionResults(Cache.ShowResultPage.get(showId)!.data);
      } else {
        fetchData(showId);
      }
    };
  }, [user]);
  
  // useEffect hook to periodically invalidate cache after 15 minutes
  useEffect(() => {
    const intervalId = setInterval(() => {
      for (const [showId, cachedData] of Cache.ShowResultPage.entries()) {
        if (Date.now() - cachedData.timestamp >= Config.CACHE_EXPIRATION_TIME) {
          Cache.ShowResultPage.delete(showId);
        }
      }
    }, Config.CACHE_EXPIRATION_TIME);
  
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    getShowResults(showId || '');
  }, [getShowResults]);

  function goBack(): void {
    navigate("/ShowResults");
  }

  return (
    <LoadingOverlay active={isLoading} className="loading-overlay">
      <>
          <Container
            maxWidth="sm"
            sx={{ marginBottom: "60px" }}
            className="page-header"
          >
            <ReplyIcon onClick={goBack} />
            {!divisionResults && !isLoading && (
              <div className="center-text">
                <h2>No results available yet.</h2>
              </div>
            )}
            <Stack spacing={2}>
              {divisionResults &&
                divisionResults.map((division) => {
                  return (
                    <>
                      <div>
                        <div className="card-row--primary show-result-division-name">
                          {division.divisionName.toUpperCase()}
                        </div>
                        {division.results &&
                          division.results.map((result) => {
                            return (
                              <>
                                <Grid
                                  container
                                  spacing={1}
                                  key={result.name + result.horse}
                                >
                                  <Grid className="show-result-name" xs={5}>
                                    <Item>{result.name}</Item>
                                  </Grid>
                                  <Grid className="show-result-horse" xs={5}>
                                    {result.horse}
                                  </Grid>
                                  <Grid className="show-result-time" xs={1}>
                                    {result.time}
                                  </Grid>
                                </Grid>
                              </>
                            );
                          })}
                      </div>
                    </>
                  );
                })}
            </Stack>
          </Container>
        </>
    </LoadingOverlay>
  );
}
export default ShowResultPage;
